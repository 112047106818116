var userAgent 	= window.navigator.userAgent.toLowerCase();
var appVersion 	= window.navigator.appVersion.toLowerCase();
var isLegacy 	= appVersion.indexOf("msie 8.0") > -1 || appVersion.indexOf("msie 9.0") > -1 || appVersion.indexOf("msie 10.0") > -1;
var isIE10 		= appVersion.indexOf("msie 10.0") > -1;
var isIE11 		= navigator.userAgent.toLowerCase().indexOf('trident/7') > -1;
var isIE8over 	= appVersion.indexOf("msie 8.0") > -1 || appVersion.indexOf("msie 9.0") > -1 || appVersion.indexOf("msie 10.0") > -1 ||  appVersion.indexOf("msie 11.0") > -1;
var isIE 		= userAgent.indexOf("msie") !== -1 || navigator.userAgent.match(/Trident/);
var isAndroid 	= navigator.userAgent.match(/Android/i);
var isIPhone 	= navigator.userAgent.match(/iPhone/i);
var isSP 		= navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/iPhone/i);
var isIPad 		= navigator.userAgent.match(/iPad/i);
var isFF 		= userAgent.indexOf("firefox")!== -1;
var isEdge 		= userAgent.indexOf("applewebkit") > -1 && userAgent.indexOf("edge") > -1;
var isMobile	= true;
var isSmallScreen = false;
var isScreenSize = 0;

if(isAndroid){
	document.documentElement.className = "os_android";
}else if(isIPhone){

}
checkBrowser();
function checkBrowser(){
	//win or mac
	if(navigator.userAgent.indexOf("Win") !== -1 ){
		if(isFF){
			document.documentElement.className = "os_windows firefox";
		}else{
			if(isIE){
				if(isIE10){
					document.documentElement.className = "os_windows legacy";
				}else if(isIE11){
					document.documentElement.className = "os_windows ie11";
				}else{
				}
			}else if(isEdge){
				document.documentElement.className = "os_windows edge";
			}else{
				document.documentElement.className = "os_windows";
			}
		}
	}
	if(navigator.userAgent.indexOf("Mac") !== -1 ){
		if(isFF){
			document.documentElement.className = "os_mac firefox";
		}else{
			document.documentElement.className = "os_mac";
		}
	}
}
