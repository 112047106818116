var apiText = [];
var loaded = false;
var grid = {
	current: 0,
	max: 0,
	margin: [20,30,40],
	marginNum: [1,3,4],
	height: 0,
	init: function(){
		var url = "/api/grid.php";
		var url02 = "/about/api/";
		var param = {
			page : 0
		};

		common.getJson(url,param).done(function(json){
			common.getJson(url02,param).done(function(json02){
				apiText = json02[0];
				grid.addHTML(json);
			});
		});
		var resizeTimer = null;
		/* window resize
		----------------------------- */
		var resizeCallback = function() {
			grid.resize();
			//one more
			setTimeout(function(){
				grid.resize();
			},300);
			resizeTimer = null;
		};
		$(window).off("resize").on("resize",function(){
			if(resizeTimer) {
				clearTimeout(resizeTimer);
			}
			resizeTimer = setTimeout(resizeCallback, 300);
		});
	},
	resize: function(){
		var $body = $("body");

		if(!$body.hasClass("resize")){
			$body.addClass("resize");
		}
		var winSize = 0;
		if (window.matchMedia('all and (min-width : 1024px)').matches) {
			isSmallScreen = false;
			isScreenSize = 2;
			winSize = 1024;
		} else if (window.matchMedia('all and (min-width : 864px)').matches) {
			isSmallScreen = false;
			isScreenSize = 1;
			winSize = 864;
		} else if(window.matchMedia('all and (min-width : 768px)').matches) {
			isSmallScreen = false;
			isScreenSize = 1;
			winSize = 768;
		} else if(window.matchMedia('all and (min-width : 0px)').matches) {
			isSmallScreen = true;
			isScreenSize = 0;
			winSize = 0;
		}

		var sizes = matrix[isScreenSize];
		var len = $("#grid a").length - 1;
		$("#grid a").removeClass(function(index, className) {
			return (className.match(/\bl\S+/g) || []).join(' ');
		});
		$("#grid a").removeClass(function(index, className) {
			return (className.match(/\bsize\S+/g) || []).join(' ');
		});
		$("#grid a").each(function(i){
			let xPos = sizes[i][0];
			let yPos = sizes[i][1];
			$(this).addClass("size" + sizes[i][2]);
			$(this).addClass("l" + sizes[i][0]);
		});
		if(isScreenSize === 0){
			$("#grid").css("height","");
			$("#grid a").each(function(j){
				$(this).css({
					"top": "",
					"width" : "",
					"height" : ""
				});
			});
		}else{
			var grid1H 	= $("#grid a").eq(1).find("img").height();

			var ratio 	= grid.margin[isScreenSize]/winSize;
			var blockW 	= $("#grid").width();

			var mb 		= Math.floor(blockW*ratio);
			var grid1W 	= Math.floor((blockW - mb*grid.marginNum[isScreenSize])/(grid.marginNum[isScreenSize]+1));
			var h = Math.floor(grid1W / 200 * 270);
			$("#grid a").each(function(j){
				let ySize = sizes[j][1];
				let marginSize = $("#grid a").eq(2).offset().left - ($("#grid a").eq(1).offset().left + $("#grid a").eq(1).width());
				let posY = grid1H*ySize + (mb*ySize);
				
				if($(this).hasClass("size1")){
					$(this).css({
						"width" : grid1W + "px",
						"height" : h + "px",
						"top" : Math.floor(posY) + "px"
					});
				}else{
					$(this).css({
						"width": (grid1W*2 + mb) + "px",
						"height" : Math.floor(h*2 + mb) + "px",
						"top" : Math.floor(posY) + "px"
					});
				}
				
			});
			var t = $("#grid a").eq(len).offset().top - $("#grid").offset().top + $("#grid a").eq(len).height();
			$("#grid").css("height", t + "px");
		}
		$("#ws span").html(isScreenSize);
		$("#matrix span").html(isScreenSize);
		setTimeout(function(){
			$body.removeClass("resize");
		},300);
	},
	addHTML: function(json){
		let HTML = "";
		var imgarry = [];
		//console.log(json);
		var data = json.grid;
		var len = data.length;

		for(var i = 0; i < len; i++){
			var post = data[i];
			var cls = "gr" + i;
			var type = post.type; 
			if(type != "post"){
				var ttl = apiText.ttl;
				var name = apiText.name;
				var text = apiText.text;
				HTML += `<a class="${cls} text" href="/about/philosophy/" title="${post.title}" style="background-image: url(/wp-content/themes/miraiga_theme/images/index/bg_today.png)">
				<img src="/wp-content/themes/miraiga_theme/images/index/bg_today.png">
				<div class="inner">
				<p class="ttl">本日の理念</p>
				<h3 class="ie">${text}</h3>
				<dl class="person">
					<dt>${ttl}</dt>
					<dd>${name}</dd>
				</dl>
				<h3>${text}</h3>
				</div>
				</a>`;
			}else{
				HTML += `<a class="${cls}" href="${post.link}" title="${post.title}" style="background-image: url(${post.img})">
				<img src="${post.img}" alt="${post.title}">
				</a>`;
				imgarry.push(post.img);
			}
		}
		$("#grid").html(HTML);
		loaded = true;
		//show after load all grid images
		preload.startLoad(imgarry.length,imgarry).done(function(){
			grid.resize();
			loaded = true;
		});
	},
	showGrid: function(){
		
		
	},
	splash: function(){
		var imgCount 	= 0;
		var imgMax 		= splashLength;
		var HTML 		= "";
		var imgarry 	= [];
		for(var i = 0; i < imgMax; i++){
			var src = '/wp-content/themes/miraiga_theme/images/splash/' + i + '.png'
			HTML += '<li><img src="' + src + '"></li>';
			imgarry.push(src);
		}
		$("#splash ul").html(HTML);
		preload.startLoad(imgMax,imgarry).done(function(){
			setTimeout(function(){
				startSplash();
			},800);
		});

		var count = 0;
		var max = imgMax;
		var duration = 33;
		function startSplash(){
			if(count < max){
				$("#splash ul li").removeClass("active");
				$("#splash ul li").eq(count).addClass("active");
				setTimeout(function(){
					count++;
					startSplash();
				},33);
			}else{
				setTimeout(function(){
					grid.afterSplash();
				},1000);
			}
		}
	},
	afterSplash: function(){
		var count = 0;
		var len = $("#grid a").length;
		if(loaded){ 
			FONTPLUS.reload();
			$("body").removeClass("splash");
		}else{
			setTimeout(function(){
				grid.afterSplash();
			},100);
		}
		
		$("#splash").off().on("oTransitionEnd mozTransitionEnd webkitTransitionEnd transitionend",function(){
			$("#splash").remove();
			$("#grid").css("height", "");
			setTimeout(function(){
				grid.resize();
				setTimeout(function(){
					addActive();
				},300);
			},50);
			
			function addActive(){
				if(count < len){
					setTimeout(function(){
						addActive();
					},66);
				}else{
					$("#index").addClass("showfooter");
				}
				var $thisGrid = $("#grid a").eq(count);
				if(!$thisGrid.hasClass("active")){
					$thisGrid.addClass("active");
				}
				count++
			}
		});
	}
}

$(function(){
	var visited = $.cookie("VISITED");
	grid.init();
	if(visited){
		grid.afterSplash();
	}else{
		grid.splash();
	}
	
	$.cookie("VISITED", true, { expires: 1 });
});