var isPage = "";
var Section = {
	top: 0,
	id: "",
	start: 0
}
var sections = [];

var common = {
	init: function(){
		var resizeFlag;
		var resizeTimer = null;
		var $body = $("body");
		isPage = $body.attr("id");
		if(isPage === "about"){
			$(".gmap").each(function(i){
				common.gMap(i);
			});
		}else if(isPage == "news"){
			news.init();
		}else if(isPage == "kyoki"){
			var base = .1;
			var delay = .4;
			$("#report a").each(function(i){
				$(this).css({
					"transition-delay" : (i*base + delay) + "s",
					"-moz-transition-delay" : (i*base + delay) + "s",
					"-o-transition-delay" : (i*base + delay) + "s",
					"-webkit-transition-delay" : (i*base + delay) + "s"
				});
			});
		}
		this.nav();
		this.resize();
		this.checkHeight();
		checkBrowser();
		common.scrl();
		setTimeout(function(){
			common.checkHash();
			if(isPage != "index"){
				$body.addClass("show_content");
				if(isPage === "news" || isPage === "kyoki" || "mission"){
					
				}else{
					$body.off().on("oTransitionEnd mozTransitionEnd webkitTransitionEnd transitionend",function(){
						$body.off();
						var topD = $(document).scrollTop(),
							endD = topD + $(window).height();
						//console.log(endD,sections[0].start)
						if(sections[0].start < endD){
							$(".block").eq(0).addClass("active");
						}
					});
				}
			}
		},600);
		/* window resize
		----------------------------- */
		var resizeCallback = function() {
			common.resize();
			resizeTimer = null;
		};

		$(window).on("resize",function(){
			if(!$body.hasClass("resize")){
				$body.addClass("resize");
			}
			if(resizeTimer) {
				clearTimeout(resizeTimer);
			}
			resizeTimer = setTimeout(resizeCallback, 300);
		});
		if(sections.length > 0){
			
		}
		$(document).off().on("scroll touchmove", function(){
			common.scrl();
		});
	},
	nav: function(){
		var $body = $("body");
		$(".pagetop a").off().on("click", function(){
			common.scrollToPosition(0);
		});
		$(".nav_page li a").off().on("click", function(){
			if($(this).parent().parent().hasClass("nopagelink")){
				
			}else{
				var blockId = $(this).attr("data-name");
				location.hash = "/" + blockId;
				common.checkHash();
			}
		});
		$("#btn_recruit_info a").off().on("click", function(){
			var posY = $("#block_job").offset().top - $("header").outerHeight()-10;
			common.scrollToPosition(posY);
		})
		$("#btn_menu").off().on("click", function(){
			if(!$body.hasClass("show_nav")){
				$("body").addClass("show_nav");
				setTimeout(function(){
					$("body").addClass("scale_nav");
				},50);
			}else{
				$("body").removeClass("scale_nav");
				$("#block_nav_sp").off().on("oTransitionEnd mozTransitionEnd webkitTransitionEnd transitionend",function(){
					$("#block_nav_sp").off();
					$body.removeClass("show_nav");
				});
			}
		});
		$(".ga").off().on("click", function(){
			var ttl = $(this).attr("data-title");
			var detail = $(this).attr("data-detail");
			if(detail === ""){
				var label = ttl;
			}else{
				var label = ttl + " / " + detail;
			}
			
			var url = $(this).attr("href");
			common.sendGa(url,label);
		});
		$(".block_job_category a.btn_toggle").off().on("click", function(){
			var idx = $(".block_job_category a.btn_toggle").index(this);
			if(!$(this).hasClass("active")){
				$(this).addClass("active");
				//common.sendGa()
			}else{
				$(this).removeClass("active");
			}
			var $blk = $(".content_recruit").eq(idx);
			$blk.animate({
				height: "toggle", opacity: "toggle"
			},600);
		});
	},
	resize: function(){
		var winW = window.innerWidth;
		var winH = window.innerHeight;
		var $body = $("body");
		this.checkHeight();
		if (window.matchMedia('all and (min-width : 1366px)').matches) {
			isSmallScreen = false;
		} else if (window.matchMedia('all and (min-width : 864px)').matches) {
			isSmallScreen = false;
		} else if(window.matchMedia('all and (min-width : 768px)').matches) {
			isSmallScreen = true;
			document.documentElement.className = "sp disable";
		} else if(window.matchMedia('all and (min-width : 0px)').matches) {
			isSmallScreen = true;
		}
		setTimeout(function(){
			if($body.hasClass("resize")){
				$body.removeClass("resize");
			}
		},300);
		if(isPage === "contact"){
			var contentHeight = $("#wrapper").height();
			if(contentHeight <= winH){
				var footerTop = Math.ceil(winH - contentHeight - $("footer").height() - $(".pagetop").outerHeight() - $("header").outerHeight())-4;
				$(".pagetop").css("margin-top", footerTop + "px");
			}else{
				$(".pagetop").css("margin-top", "");
			}
		}
	},
	scrollToPosition: function(posY){
		var $html 	= $("html,body"),
			ease 	= "easeInOutCubic";
		$html.animate({
			scrollTop : posY + "px"
		},{
			"duration" 	: 1000,
			"easing" 	: ease
		});
	},
	getJson: function(url,params){
		var defer 	= $.Deferred();
		var url 	= url;
		$.ajax({
			type: "GET",
			dataType: 'json',
			url: url,
			data: params,
			success	: defer.resolve,
			error: defer.reject
		});
		return defer.promise();
	},
	checkHeight: function(){
		sections = [];
		$(".block").each(function(i){
			var _b = object(Section);
			_b.top = Math.floor($(this).offset().top);
			_b.id = $(this).attr("id");
			_b.start = Math.floor(_b.top + $(window).height()*.3);
			sections.push(_b);
		});
	},
	scrl: function(){
		var topD 	= $(document).scrollTop(),
			endD 	= topD + $(window).height(),
			len 	= sections.length,
			$body 	= $("body"),
			$header = $("header"),
			pageTopStopPos = $(document).height() - $(".pagetop").height(),
			halfWin = $(window).height()*.7;

		if(topD > 0){
			if(!$header.hasClass("small")){
				$("header").addClass("small");
				
			}
		}else{
			if($header.hasClass("small")){
				$("header").removeClass("small");
				
			}
		}
		if(endD >= pageTopStopPos){
			if(!$(".pagetop").hasClass("btm")){
				$(".pagetop").addClass("btm");
			}
		}else if(endD < pageTopStopPos){
			if($(".pagetop").hasClass("btm")){
				$(".pagetop").removeClass("btm");
			}
		}
		if(topD > halfWin){
			if(!$("footer").hasClass("show")){
				$("footer").addClass("show");
			}
		}else{
			if($("footer").hasClass("show")){
				$("footer").removeClass("show");
			}
		}

		for(var i = 0; i < len; i++){
			if(i == len - 1){
				if(endD >= sections[i].start){
					activeBlock(i);
				}
			}else{
				if(endD >= sections[i].start){
					activeBlock(i);
				}
			}
		}
		
		function activeBlock(i){
			var blk = $(".block").eq(i);
			if(blk.attr("id") === "block_job"){
				if(!$("#btn_recruit_info").hasClass("active")){
					$("#btn_recruit_info").addClass("active");
				}
			}else{
				if(isPage === "recruit"){
					if($("#btn_recruit_info").hasClass("active")){
						$("#btn_recruit_info").removeClass("active");
					}
				}
			}
			if(!blk.hasClass("active")){
				blk.addClass("active");
			}
		}
	},
	checkHash: function(){
		var prop = location.hash;
		var hashArry = prop.split("/");
		var hash = hashArry[1];
		if(isPage === "business"){
			var blkId = "#" + hash;
		}else{
			var blkId = "#block_" + hash;
		}
		if(hashArry.length > 1){
			var posY = $(blkId).offset().top - $("header").outerHeight();
			common.scrollToPosition(posY);
		}
	},
	sendGa: function(url,label){
		ga('send', 'event', 'MiraigaLab', 'click', label);
	}
}
$(function(){
	common.init();
});